import React from "react"

import { PhotoPlaceholder } from "react-placeholder-image"
import { GiStoneThrone } from "react-icons/gi"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AgavePage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="grid-2-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", justifyContent: "space-between" }}
        className="has-padding-4  has-bg-blue align-content-vcenter has-txt-white"
      >
        <div>
          <h1 className="has-txt-bold" style={{ marginBottom: "1rem" }}>
            Agave
          </h1>
          <h3>
            <span className="has-mb-5 tags has-txt-bold">Logo design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">Print design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">Product Design</span>{" "}
          </h3>
        </div>
        <p>
          Agave is a customized furniture and art workshop that delivers
          handcrafted goods and Sparks new life to living spaces. A modern
          approach redefining spaces. <br /> <br />
          We helped the brand from it’s very inception and made sure that the
          vision associated with the process remained intact. Customized
          illustrations and minimal design formed the backbone of the brand
          program. The logo was first hand painted and then converted to digital
          art giving us the utmost pleasure to get our hands back in the grind.
        </p>
      </div>

      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/agave/1.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/agave/2.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="600"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/agave/3.jpg"} alt="" />
      </div>
    </div>

    <div className="grid-1-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/agave/4.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", cursor: "pointer" }}
      >
        <img src={"/images/agave/5.jpg"} alt="" />
      </div>
    </div>
  </Layout>
)

export default AgavePage
